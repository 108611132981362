<template>
  <div class="content-wrapper">
    <div class="content-lookup">
      <div class="lookup-left">
        <ul class="lookup-condition">
          <li class="field">
            <div class="title">기준일자</div>
            <ul class="content">
              <li class="item date">
                <input-date
                  type="lookup-condition"
                  v-model="searchOptions.bsnDate"
                  :notClear="true"
                />
              </li>
            </ul>
          </li>
          <li class="field">
            <div class="title">처리구분</div>
            <ul class="content">
              <li class="item">
                <ejs-dropdownlist
                  cssClass="lookup-condition-dropdown"
                  ref="drmncyProcessDiv"
                  v-model="searchOptions.drmncyProcessDiv"
                  v-bind="drmncyProcessDivDropdownListProps"
                />
              </li>
            </ul>
          </li>
          <li class="field">
            <div class="title">회원명</div>
            <ul class="content">
              <li class="item">
                <input-text
                  v-model="searchOptions.memberName"
                />
              </li>
            </ul>
          </li>
        </ul>
        <div class="lookup-lookup">
          <erp-button
              button-div="GET"
              :ignore="isPopupOpened"
              :is-shortcut-button="true"
              @click.native="onViewButtonClicked"
          >
            조회
          </erp-button>
        </div>
      </div>
    </div>
    <div class="content-body">
      <article class="body-article">
        <!-- 아코디언 : accordion / 닫힘 : close -->
        <section class="article-section section-01">
          <div class="section-header">
            <div class="header-left">
              <div class="header-title">휴면회원 개인정보 폐기</div>
              <div class="header-caption">[{{ numberWithCommas(dormantMembersByCount) }}건]</div>
            </div>
            <div class="header-right">
              <ul class="header-button">
                <li v-if="isDrmncyAcntUse && isDrmncyProcessMANUAL">
                  <erp-button
                      button-div="GET"
                      :is-icon-custom="true"
                      @click.native="onSmsSendButtonClicked"
                  >
                    SMS 발송
                  </erp-button>
                </li>
                <li v-if="isDrmncyAcntUse && isDrmncyProcessMANUAL">
                  <erp-button
                      button-div="GET"
                      :is-icon-custom="true"
                      @click.native="onDisposeButtonClicked"
                  >
                    폐기
                  </erp-button>
                </li>
                <li class="print">
                  <erp-button
                      button-div="FILE"
                      @click.native="onExcelButtonClicked"
                  >
                    Excel
                  </erp-button>
                </li>
              </ul>
            </div>
          </div>
          <div class="section-body">
            <div class="body-grid">
              <ejs-grid-wrapper
                ref="grid"
                v-bind="gridOptions"
                :dataSource="dormantMembers"
                @queryCellInfo="onGridQueryCellInfo"
                @gridCheckboxChanged="gridCheckboxChanged"
                @actionComplete="onGridActionComplete"
                @headerSelectAllCheckboxClicked="onGridHeaderSelectAllCheckboxClicked"
              />
            </div>
          </div>
        </section>
      </article>
    </div>
  </div>
</template>

<style scoped>
body .appContent .body-article .section-body {overflow: hidden; border: none;}
</style>

<script>
import commonMixin from '@/views/layout/mixin/commonMixin';
import confirmDialogMixin from '@/views/layout/mixin/messagePopupDialogMixin';
import routeViewMixin from '@/views/layout/mixin/routeViewMixin';

import EjsGridWrapper from "@/components/common/EjsGridWrapper";
import InputDate from "@/components/common/datetime/InputDate.vue";
import InputText from "@/components/common/text/InputText.vue";
import ErpButton from "@/components/button/ErpButton.vue";
import {Edit, ExcelExport, Filter, ForeignKey, Page, Resize} from "@syncfusion/ej2-vue-grids";
import GolfErpAPI from "@/api/v2/GolfErpAPI";
import {getFormattedDate} from "@/utils/date";
import {numberWithCommas} from "@/utils/number";
import {
  commonCodesGetCommonCode,
  commonCodesGetCommonCodeAttrbNameByCodeAndIdx,
  commonCodesGetStandardInfo
} from "@/utils/commonCodes";
import {gridUtilGetMemberNoColumnAccess, gridUtilGetTelColumnAccess} from "@/utils/gridUtil";

export default {
  name: "DestructionOfPersonalInformationOfDormant",
  components: {
    InputDate,
    InputText,
    ErpButton,
    EjsGridWrapper,
  },
  mixins: [commonMixin, confirmDialogMixin, routeViewMixin],
  data() {
    return {
      searchOptions: {
        bsnDate: null,
        drmncyProcessDiv: "0",
        memberName: null,
      },
      smsSendType: "MEMB_DOPIOD",
      bizName: commonCodesGetStandardInfo("bizName"),
      bizNameLocation: commonCodesGetStandardInfo("bizNameLocation"),
      kakaoDispatchKey: commonCodesGetStandardInfo("kakaoDsptchKey"),
      dormantMembers: [],
      dormantMembersByCount: 0,
      commonCodeField: { text: "comName", value: "comCode" },
      toExcludeIndexList: [],
    };
  },
  created() {
    this.searchOptions.bsnDate = getFormattedDate(new Date());
  },
  computed: {
    isPopupOpened() {
      return false;
    },
    drmncyProcessDivDropdownListProps() {
      const dataSource = commonCodesGetCommonCode("DRMNCY_PROCESS_DIV").filter(item => !["2","3"].includes(item.comCode));
      dataSource.unshift({
        comCode: "0",
        comName: "SMS 미발송",
      });

      return {
        allowFiltering: false,
        dataSource,
        fields: this.commonCodeField,
      };
    },
    isDrmncyAcntUse() {
      return commonCodesGetStandardInfo("drmncyAcntUse") || false;
    },
    isDrmncyProcessMANUAL() {
      return commonCodesGetStandardInfo("autoDiv") === "MANUAL" || false;
    },
    gridOptions() {
      const columns = [
        {
          field: "select",
          headerText: "선택",
          type: "boolean",
          editType: "booleanedit",
          displayAsCheckBox: true,
          width: 60,
          textAlign: "Center",
          allowFiltering: false,
          allowSorting: false,
          isSelectAllColumn: true,
        },
        {
          field: "memberNo",
          headerText: "회원번호",
          type: "string",
          width: 90,
          textAlign: "Center",
          allowEditing: false,
          valueAccessor: (field, data) =>
            gridUtilGetMemberNoColumnAccess(field, data),
        },
        {
          field: "memberName",
          headerText: "회원명",
          type: "string",
          allowEditing: false,
        },
        {
          field: "webId",
          headerText: "웹ID",
          type: "string",
          allowEditing: false,
        },
        {
          field: "hpNo",
          headerText: "핸드폰번호",
          type: "string",
          width: 100,
          textAlign: "center",
          allowEditing: false,
          valueAccessor: (field, data) =>
            gridUtilGetTelColumnAccess(field, data),
        },
        {
          field: "stdDate",
          headerText: "기준일자",
          type: "string",
          width: 90,
          textAlign: "center",
          allowEditing: false,
        },
        {
          field: "expirDate",
          headerText: "만료일자",
          type: "string",
          width: 90,
          textAlign: "center",
          allowEditing: false,
        },
        {
          field: "smsSendDt",
          headerText: "SMS 발송 일시",
          type: "string",
          width: 150,
          textAlign: "center",
          allowEditing: false,
        },
      ];

      if (commonCodesGetStandardInfo("homepageLoginConf")) {
        columns.push({
          field: "lastLoginDate",
          headerText: "최종 로그인 일자",
          type: "string",
          width: 130,
          textAlign: "center",
          allowEditing: false,
        });
      }

      if (commonCodesGetStandardInfo("resveRceptConf")) {
        columns.push({
          field: "lastResveDate",
          headerText: "최종 예약일자",
          type: "string",
          width: 120,
          textAlign: "center",
          allowEditing: false,
        });
      }

      if (commonCodesGetStandardInfo("cmpnInsertConf")) {
        columns.push({
          field: "lastCmpnInsertDate",
          headerText: "최종 동반자 등록일",
          type: "string",
          width: 140,
          textAlign: "center",
          allowEditing: false,
        });
      }

      if (commonCodesGetStandardInfo("visitInsertConf")) {
        columns.push({
          field: "lastVisitDate",
          headerText: "최종 내장일자",
          type: "string",
          width: 120,
          textAlign: "center",
          allowEditing: false,
        });
      }

      if (commonCodesGetStandardInfo("depositBlceConf")) {
        columns.push({
          field: "depositBlce",
          headerText: "최종 선수금 잔액",
          type: "number",
          format: "N",
          width: 130,
          textAlign: "right",
          allowEditing: false,
        });
      }

      columns.push({
        field: "drmncyProcessDiv",
        headerText: "휴면처리구분",
        type: "string",
        width: 120,
        textAlign: "center",
        allowEditing: false,
        isCommonCodeField: true,
        groupCode: "DRMNCY_PROCESS_DIV",
      });

      return {
        provides: [
          Edit,
          Filter,
          Resize,
          Page,
          ExcelExport,
          ForeignKey,
        ],
        selectionSettings: {
          type: "Single",
          mode: "Both",
          enableToggle: false,
        },
        allowExcelExport: true,
        allowPaging: true,
        noColumnWidth: 40,
        pageSettings: { pageSize: 50 },
        columns,
      };
    },
  },
  mounted() {},
  methods: {
    numberWithCommas,
    onViewButtonClicked() {
      this.fetchDestructionOfPersonalInformation();
    },
    async fetchDestructionOfPersonalInformation() {
      this.toExcludeIndexList = [];
      const data = await GolfErpAPI.fetchDestructionOfPersonalInformation({
        bsnDate: this.searchOptions.bsnDate,
        drmncyProcessDiv: this.searchOptions.drmncyProcessDiv === "%" ? null : this.searchOptions.drmncyProcessDiv,
        memberName: this.searchOptions.memberName,
      });
      console.log('data.===>', data);
      this.dormantMembers = data.map((item, idx) => ({
        ...item,
        _rid: idx + 1,
        select: false,
      }));
    },
    onGridQueryCellInfo(args) {
      const {
        cell,
        column: { field },
      } = args;

      if (field === "select") {
        cell.classList.add(this.$t("className.grid.modifyArea"));
      }
    },
    async gridCheckboxChanged(args) {
      const {
        columnName: field,
        rowData,
        rowIndex,
      } = args;

      if (field === "select") {
        // 휴면처리구분이 미처리면 선택 안되도록
        if (rowData.drmncyProcessDiv === "2") {
          await this.$nextTick();
          this.$refs.grid.saveCell();
          this.$refs.grid.updateCell(rowIndex, "select", false);
          this.$refs.grid.setSelectAllColumnCheckboxes();
          return;
        }
        const hasRIdx = this.toExcludeIndexList
          ?.findIndex(item => item === rowData._rid);
        const findIndex = this.dormantMembers
          ?.findIndex(item => item._rid === rowData._rid);
        if (findIndex > -1) {
          if (hasRIdx > -1) {
            this.dormantMembers[findIndex].select = false;
            delete this.toExcludeIndexList[hasRIdx];
            this.toExcludeIndexList = this.toExcludeIndexList
              ?.filter(item => !!item);
          } else {
            this.dormantMembers[findIndex].select = true;
            this.toExcludeIndexList.push(rowData._rid);
          }
          this.$forceUpdate();
        }
      }
    },
    onGridActionComplete() {
      this.dormantMembersByCount = numberWithCommas(
        this.$refs.grid?.getGridBatchCount() || 0
      );
    },
    onGridHeaderSelectAllCheckboxClicked(args) {
      const {
        field,
        value,
      } = args;

      if (field === "select") {
        this.toExcludeIndexList = [];
        this.dormantMembers.map(item => item.select = false);

        if (value) {
          const filteredRecords = this.$refs.grid.getFilteredRecords();
          if (filteredRecords.length > 0) {
            filteredRecords.forEach(record => {
              if (record.drmncyProcessDiv !== "2") {
                record.select = value;
                this.toExcludeIndexList.push(record._rid);
              }
            });
          } else {
            this.dormantMembers.map(item => {
              if (item.drmncyProcessDiv !== "2") {
                item.select = value;
                this.toExcludeIndexList.push(item._rid);
              }
            });
          }
        }
      }
    },
    onExcelButtonClicked() {
      if (!(this.dormantMembers.length > 0)) {
        this.errorToast("조회된 데이터가 없습니다");
        return;
      }

      // 회원명 Column의 Width값이 설정되지 않아 엑셀 저장시 해당 항목이 숨겨지는 부분을 방지하기 위함.
      const excelColumns = JSON.parse(JSON.stringify(this.gridOptions.columns));
      excelColumns.filter(item => item.field === "memberName")[0].width = 120;
      excelColumns.filter(item => item.field === "webId")[0].width = 120;
      this.$refs.grid.excelExport({
        columns: excelColumns,
      });
    },
    async onSmsSendButtonClicked() {
      if (!(this.dormantMembers.length > 0)) {
        this.errorToast("조회된 데이터가 없습니다");
        return;
      }

      const selectData = this.dormantMembers.filter(item => this.toExcludeIndexList.includes(item._rid));
      console.log('selectData.===>', selectData);

      if (!(selectData.length > 0)) {
        this.errorToast("선택된 데이터가 존재하지 않습니다");
        return;
      }

      if (selectData.filter(item => item.drmncyProcessDiv === "3").length > 0) {
        this.errorToast("폐기처리가 완료된 회원은 SMS를 보낼 수 없습니다");
        return;
      }

      if (!(await this.confirm("SMS를 발송하시겠습니까?"))) {
        return;
      }

      const smsSendData = [];

      selectData.forEach(item => {
        smsSendData.push({
          contactTel: item.hpNo,
          name: item.memberName,
          year: commonCodesGetStandardInfo("drmncyAcntProcess"),
          ccName: this.bizName,
          webid: item.webId,
          expirDate: item.expirDate,
          membershipId: item.membershipId,
          memberDiv: item.memberDiv,
          stdDate: item.stdDate,
          lastLoginDate: item.lastLoginDate,
          lastResveDate: item.lastResveDate,
          lastCmpnInsertDate: item.lastCmpnInsertDate,
          lastVisitDate: item.lastVisitDate,
          depositBlce: item.depositBlce,
          drmncyId: item.drmncyId,
        });
      });

      console.log('smsSendData.===>', smsSendData);

      const smsWords = await GolfErpAPI.fetchAllSmsWords();
      const smsSendType = smsWords.find(
        data => data.smsSendType === this.smsSendType
      );

      console.log('smsSendType.===>', smsSendType);
      if (!smsSendType?.smsSendType) {
        this.errorToast("SMS 문구가 존재하지 않습니다");
        return;
      }

      let message = [];
      message.push(smsSendType.smsWords);
      if (smsSendType.aditWords) {
        message.push(smsSendType.aditWords);
      }
      if (this.bizNameLocation === "DOWN") {
        message.push(this.bizName);
      }
      message = message.join("\n");

      let smsRequestList = [];

      for (const data of smsSendData) {
        if (!data.contactTel) {
          continue;
        }

        const smsInfo = {
          type: this.smsSendType,
          kakaoFlag: smsSendType.kakaoSendFlag,
          smsKind: smsSendType.kakaoSendFlag
            ? smsSendType.smsKind
            : null,
          kakaoDispatchKey: smsSendType.kakaoSendFlag
            ? commonCodesGetStandardInfo("kakaoDsptchKey")
            : null,
          kakaoTemplateKey: smsSendType.kakaoSendFlag
            ? smsSendType.kakaoTemplateCode
            : null,
          key: 0,
          reservedFlag: false,
          reserveDateTime: null,
          receiverName: data.name,
          receiverNumber: data.contactTel,
          subject: smsSendType.smsSendTypeName,
          message: message,
          replaceDataMap: {
            name: data.name.concat(commonCodesGetCommonCodeAttrbNameByCodeAndIdx("MEMBER_DIV", data.memberDiv, 1)),
            year: data.year,
            ccName: data.ccName,
            webid: data.webid ? data.webid : "",
            expirDate: data.expirDate,
            membershipId: data.membershipId,
            stdDate: data.stdDate,
            lastLoginDate: data.lastLoginDate,
            lastResveDate: data.lastResveDate,
            lastCmpnInsertDate: data.lastCmpnInsertDate,
            lastVisitDate: data.lastVisitDate,
            depositBlce: data.depositBlce,
            drmncyId: data.drmncyId,
            headerMemo: this.bizNameLocation === "UP" ? (this.bizName ? this.bizName : null) : null,
          },
          dispatchNumber: smsSendType.dsptchNo.replace(/-/gi, ""),
          sender: "ERP",
        };
        smsRequestList.push(smsInfo);
      }

      console.log('smsRequestList.===>', smsRequestList);

      if (!(smsRequestList.length > 0)) {
        this.errorToast("전송 가능한 데이터가 없습니다");
        return;
      }

      await GolfErpAPI.destructionOfPersonalInformationSmsSend(smsRequestList);

      this.onViewButtonClicked();
    },
    async onDisposeButtonClicked() {
      if (!(this.dormantMembers.length > 0)) {
        this.errorToast("조회된 데이터가 없습니다");
        return;
      }

      const selectData = this.dormantMembers.filter(item => this.toExcludeIndexList.includes(item._rid));
      console.log('selectData.===>', selectData);

      if (!(selectData.length > 0)) {
        this.errorToast("선택된 데이터가 존재하지 않습니다");
        return;
      }

      if (selectData.filter(item => item.drmncyProcessDiv === "3").length > 0) {
        this.errorToast("폐기처리가 완료된 회원은 폐기 처리를 할 수 없습니다");
        return;
      }

      if (selectData.filter(item => item.drmncyProcessDiv !== "1").length > 0) {
        this.errorToast("SMS를 보내지 않은 회원은 폐기 처리 할 수 없습니다");
        return;
      }

      if (!(await this.confirm("폐기 처리를 진행 하시겠습니까?"))) {
        return;
      }

      await GolfErpAPI.destructionOfPersonalInformation(selectData.filter(item => item.drmncyId).map(item => item.drmncyId));

      this.onViewButtonClicked();
    },
  },
};
</script>
